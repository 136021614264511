<template>
    <section class="container-page">
        <div class="px-3 pt-5">
            <div class="text-center text-white">
                <h2>Publish</h2>
                <p class="mt-3">Play and Share your audio</p>
            </div>
            <div class="d-flex justify-content-center">
                <div>
                    <div class="mt-3 mb-5 ">
                        <!-- <img v-if="images_cover != '-' && images_cover != '' && images_cover != null && images_cover != undefined && images_cover != 'undefined'"
                            :src="images_cover" alt="" class="img-preview">
                        <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-preview" /> -->
                        <div style="width:32vw">
                            <video ref="videoPlayer" width="100%" height="300px" controls controlsList="nodownload">
                                <source :src="finalPath" type="video/mp4" class="shadow-video">
                            </video>
                        </div>
                        <div class="py-4 w-100 text-white">
                            <!-- <div class="d-flex align-items-center">
                                <img :src="require(`@/assets/images/icons/${iconFill}`)" alt="Play" class="img-fluid cursor-pointer">
                                <div class="w-100 ml-3">
                                    <b-progress height="5px" :min="0" :max="100" variant="primary" :value="30"></b-progress>
                                </div>
                                <p class="ml-3 mb-0">0.30</p>
                            </div> -->
                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <h4 class="font-weight-normal">Public</h4>
                                <vs-switch color="success" v-model="public_mode"/>
                            </div>
                            <p class="mb-0 mt-2 text-left">Your content will be enjoyed by everyone.</p>
                        </div>
                    </div>
                    <div class="mb-5 mt-2 d-flex justify-content-between list-share">
                        <div class="dropdown dropup">
                            <button 
                                class="btn btn-download dropdown-toggle" 
                                type="button" 
                                id="shareActions" 
                                data-toggle="dropdown"
                                aria-haspopup="true" 
                                aria-expanded="false"
                            >
                                <img src="@/assets/images/icons/ic_upload.svg" alt="Share" class="ml-2">
                                <span class="mx-2">Share</span>
                            </button>
                            <div class="dropdown-menu mb-2" aria-labelledby="shareActions">
                                <button class="btn" @click="copyLink(finalPath)"> <span class="text-white">Copy Link</span></button>
                                <button class="btn" @click="downloadVideomp3"> <span class="text-white text-nowrap">Download mp3</span></button>
                                <button class="btn" @click="downloadVideomp4"> <span class="text-white text-nowrap">Download mp4</span></button>
                            </div>
                        </div>
                        <button class="py-2 btn-share btn btn-lg" @click="publish"><span class="mx-3">Save & Publish</span></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { postContents, updateContent } from "@/services/contents/contents.service";
    import { downloadAudio } from "@/services/audio/audio.service"
    import { base_url_machine_learning } from "../../../../config/base_url";
    import { exportUtils } from "@/mixins/ExportUtils";
    import { AlertUtils } from "@/mixins/AlertUtils";
    
    import axios from "axios"
    import $ from 'jquery';
    export default {
        mixins: [exportUtils, AlertUtils],

        data() {
            return {
                images_cover: null,
                iconFill: 'ic_play_circle_editor.svg',
                public_mode: true,
                finalPath : null,
                audio_name : null
            }
        },
        methods: {
            setSideBar() {
                this.$store.commit('IS_SIDEBAR_ACTIVE', false);
            },

            publish(){
            let mode = this.$route.params.mode
            let id = this.$route.params.id
            let content_name = "Content Audio"
            if(localStorage.getItem('content_name_tta')) {
                    content_name = localStorage.getItem('content_name_tta')
            }
            if(mode === 'draft' || mode === 'edit'){ 
                let formData = {
                    "content_type": "audio",
                    "content_name": content_name,
                    "content_file": localStorage.getItem("final_path_tta"),
                    "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
                    "image_array": sessionStorage.getItem("imageArray"),
                    "audio_array": sessionStorage.getItem("listTextToAudio"),
                    "music_array": sessionStorage.getItem("musicArray"),
                    "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
                    "public_mode" : this.public_mode,
                    "volume_music" : parseFloat(localStorage.getItem('volume_music_tta')),
                    "volume_audio" : parseFloat(localStorage.getItem('volume_video_tta')),
                    "job_id" : localStorage.getItem("jobs_id_tta"),
                    "is_draft": false
                }

                updateContent(formData, id)
                .then((response) => {
                    if(response.status == true){
                        this.$store.state.generatedAudioContent = false
                        for (var i = 0; i < localStorage.length; i++){
                            var key = localStorage.key(i);
                            if (key != "users") {
                                localStorage.removeItem(key);
                            }
                        }
                        this.removeDataStorage()
                        this.$router.push({ name : 'My-Quiz' })
                    } else {
                        console.log(response)
                    }
                })
            }
            else {

                let formData = {
                    "content_type": "audio",
                    "content_name": content_name,
                    "content_file": localStorage.getItem("final_path_tta"),
                    "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
                    "image_array": sessionStorage.getItem("imageArray"),
                    "audio_array": sessionStorage.getItem("listTextToAudio"),
                    "music_array": sessionStorage.getItem("musicArray"),
                    "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
                    "public_mode" : this.public_mode,
                    "job_id" : localStorage.getItem("jobs_id_tta"),
                    "is_draft": false
                }

                postContents(formData)
                .then((response) => {
                    if(response.status == true){
                        this.$store.state.generatedAudioContent = false
                        for (var i = 0; i < localStorage.length; i++){
                            var key = localStorage.key(i);
                            if (key != "users") {
                                localStorage.removeItem(key);
                            }
                        }
                        this.removeDataStorage()
                        this.$router.push({ name : 'My-Quiz' })
                    } else {
                        this.$router.push({ name : 'My-Quiz' })
                    }
                })
            } 
            },
            copyLink(link){
                let vm = this
                vm.$copyText(link).then(function () {
                    vm.alertSuccess('Link successfully copied')
                }, function () {
                    vm.alertFail('Link failed to copy')
                })
            },

            downloadVideomp3() {
                let vm = this
                let jobsId = null;

                if(localStorage.jobs_id_tta){
                    jobsId = localStorage.jobs_id_tta    
                }

                if(jobsId != null && jobsId != undefined){
                    let formData = {
                        "job_id" : jobsId
                    }
                    downloadAudio(formData)
                    .then((response) =>{
                        if(response.status == 200){
                            let link_mp3 = base_url_machine_learning + response.data
                            axios.get(link_mp3, { responseType: 'blob' })
                            .then(res => {
                                vm.exportAudio(res.data, vm.audio_name ? vm.audio_name : 'Audio')
                                vm.alertSuccess('Audio successfully downloaded')
                            });
                        }
                    })
                } else {
                    vm.alertFail('Something went wrong !')
                }
            },

            downloadVideomp4() {
                let vm = this
                if(vm.finalPath != null) {
                    axios.get(vm.finalPath, { responseType: 'blob' })
                    .then(res => {
                        vm.exportVidio(res.data, vm.audio_name ? vm.audio_name : 'Audio')
                        vm.alertSuccess('Video successfully downloaded')
                    });
                } else {
                    vm.alertFail('Something went wrong !')
                }
            },

            removeDataStorage(){
                sessionStorage.removeItem("imageArray")
                sessionStorage.removeItem("listTextToAudio")
                sessionStorage.removeItem("musicArray")
                localStorage.removeItem("final_path_tta")
                localStorage.removeItem("image_thumbnail_tta")
                localStorage.removeItem("content_name_tta")
                localStorage.removeItem("generatedTtaFinal")
                localStorage.removeItem("course_id")
                localStorage.removeItem("volume_music_tta")
                localStorage.removeItem("volume_video_tta")
                localStorage.removeItem("jobs_id_tta")
            },
        },
        mounted() {
            this.setSideBar();
            $("section.container-page").parent().addClass("m-0")

            if(localStorage.final_path_tta){
                this.finalPath = localStorage.final_path_tta
            }

            if(localStorage.content_name_tta){
                this.audio_name = localStorage.content_name_tta
            }
        },
    }
</script>

<style scoped>
.container-page {
        /* margin-left: -260px; */
        min-height: calc(100vh - 120px);
        height: 100%;
    }
    .preview {
        position: relative;
    }
    .preview .audio-player {
        position: absolute;
        bottom: 1rem;
    }

    .img-preview {
        width: 32vw;
        border-radius: 10px;
    }

    .preview audio {
        width: 30vw;
        height: 40px;
    }

    audio::-webkit-media-controls-panel {
        width: 100%;
    }
    .btn-download {
        color: #fff;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 30px;
        padding: 10px;
    }
    .btn-share {
        color: white;
        background-color: #6D75F6;
        border: 2px solid #6D75F6;
        border-radius: 8px;
    }
    .btn-my-collections {
        color: #6D75F6;
        background-color: transparent;
        border: none;
    }

    .dropdown-menu{
        background-color: #2D2D2D;
        min-width: 5rem;
    }

    .list-share .dropdown-menu {
        background: #2D2D2D;
    }

    .list-share .dropdown-item {
        color: white;
    }

    .list-share .dropdown-item:hover {
        background-color: #8C8C8C;
    }

    .list-share .dropup .dropdown-toggle::after {
        display: none !important;
    }

    .progress {
        background-color: #7A7A7A !important;
    }
</style>

<style>
    .progress .bg-primary {
        background-color: #6D75F6 !important;
    }
</style>